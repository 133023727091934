<template>
  <div id="user">
    <div v-if="this.found">
      <h4>Manage User</h4><br />

      <p style="font-size: 20px;">{{ user.name }}
        <i class="fas fa-pencil" @click="() => changeUserDetails('name')"></i>
      </p>

      <p>
        <span v-if="user.address">{{ user.address }}</span>
        <span v-if="user.address && user.postcode">, </span>
        <span v-if="user.postcode">{{ user.postcode }}</span>
      </p>

      <p v-if="user.email">{{ user.email }}
        <i class="fas fa-pencil" @click="() => changeUserDetails('email')"></i>
      </p>
      <p v-if="user.mobile">{{ user.mobile }}</p>

      <p class="odd" v-if="user?.emergency_contact?.name && user?.emergency_contact?.mobile">
        Emergency Contact:
        <br />
        {{ user?.emergency_contact?.name + ", " + user?.emergency_contact?.mobile }}
      </p>

      <p style="margin-bottom: 0px;">Credits:<span style="display: inline-block; width: 10px"></span> {{ credits }}
        <a id="toggleShowPackages" v-if="!showPackages" @click="showPackages = true">Show packages <i
            class="fas fa-chevron-down"></i></a>
        <a id="toggleShowPackages" v-else @click="showPackages = false">Hide packages <i
            class="fas fa-chevron-up"></i></a>
      </p>
      <table v-if="showPackages" id="packages">
        <thead>
          <tr>
            <th>Amount</th>
            <th>Remaining</th>
            <th>Expires</th>
            <th>Subscription</th>
            <th>Site</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="credit in user.credits" :key="credit._id">
            <td>{{ credit.amount }}</td>
            <td>{{ credit.amount - credit.used }}</td>
            <td>{{ credit.expires ? new Date(credit.expires).toLocaleDateString() : '' }}</td>
            <td>{{ credit.subscription ? 'Yes' : 'No' }}</td>
            <td>{{ credit.site_name }}</td>
            <td><i class="fas fa-x" @click="() => removePackage(credit._id.toString())"></i></td>
          </tr>
          <tr>
            <td><input type="number" v-model="newPackage.amount" placeholder="Amount" /></td>
            <td>-</td>
            <td><input type="date" v-model="newPackage.expires" /></td>
            <td><input type="checkbox" v-model="newPackage.subscription" /></td>
            <td><select v-model="newPackage.site">
                <option v-for="site in sites" :key="site._id" :value="site._id">{{ site.name }}</option>
              </select></td>
            <td><i class="fas fa-plus" @click="addPackage"></i></td>
          </tr>
        </tbody>
      </table><br />
      <br />


      <p style="margin-bottom: 0px;">Previous Visits</p>
      <div id="visits">
        <i class="fa fa-minus" @click="changeVisits(false)"></i>
        {{ visits }}
        <i class="fa fa-plus" @click="changeVisits(true)"></i>
      </div><br />

      <p v-if="this.user.isVerified === true && !this.user.isPT" id="verified">
        Verified:
        <span style="color: rgb(1, 179, 1)">Yes</span>
        <button @click="changeVerificationStatus(false)">Remove</button>
      </p>
      <p v-else-if="!this.user.isPT" id="verified">
        Verified:
        <span style="color: #ccc">No</span>
        <button @click="changeVerificationStatus(true)">Activate</button>
      </p>

      <p v-if="this.user.admin === true" id="admin">
        Admin:
        <span style="color: rgb(1, 179, 1)">Yes</span>
        <button class="adminBtn" @click="changeAdminStatus(false)">Remove</button>
      </p>
      <p v-else id="admin">
        Admin:
        <span style="color: #ccc">No</span>
        <button class="adminBtn" @click="changeAdminStatus(true)">Make admin</button>
      </p>

      <!-- <button id="saveCredits" @click="saveProfile">Save</button><br /><br /> -->
    </div>

    <div v-else>
      <h1>User Not Found</h1>
    </div>
  </div>

  <div id="tint" @click="close"></div>
</template>

<script>
import {
  findUser,
  getSites,
  addPackage,
  removePackage,
  addVisits,
  updateUser,
  toggleVerification,
  toggleAdmin,
} from '@/app/admin-routes';

export default {
  name: 'UserModal',
  props: {
    id: String,
    email: String,
    close: Function
  },
  data() {
    return {
      user: {
        name: '',
        address: '',
        postcode: '',
        mobile: '',
        emergency_contact: {
          name: '',
          mobile: '',
        },
        credits: [],
        isVerified: false,
        isPT: false,
      },
      showPackages: false,
      newPackage: {
        amount: 0,
        expires: new Date(Date.parse(new Date()) + 2592000000 * 6).toISOString().substring(0, 10),
        subscription: false,
      },
      credits: 0,
      visits: 0,
      visitsChange: 0,
      found: true,
      display: 'regular',
      sites: [],
    }
  },
  methods: {
    async renderUser() {
      const user = await findUser(this.email);
      if (!user) {
        this.found = false;
        return;
      }
      this.user = user;
      let calcCredits = 0;
      for (const credit of user.credits) {
        calcCredits += (credit.amount - credit.used);
      }
      this.credits = calcCredits;
      this.visits = user.visits;
      this.sites = await getSites();
      for (const credit of user.credits) {
        credit.site_name = this.sites.find(site => site._id === credit.site)?.name;
      }
    },
    async addPackage() {
      if (
        this.newPackage.amount < 0 ||
        !new Date(this.newPackage.expires)   
      ) return alert('Please fill in all fields');
      else if (new Date(this.newPackage.expires) < new Date()) return alert('Please enter a valid expiry date');
      await addPackage(this.user._id.toString(), this.newPackage);
      this.user.credits.push({...this.newPackage, used: 0});
      this.renderUser();
      this.newPackage = {
        amount: 0,
        expires: new Date(Date.parse(new Date()) + 2592000000 * 6).toISOString().substring(0, 10),
        subscription: false,
      };
    },
    async removePackage(id) {
      const response = confirm('Are you sure you want to remove this package?');
      if (!response) return;
      await removePackage(this.user._id.toString(), id);
      this.user.credits = this.user.credits.filter(credit => credit._id !== id);
      this.renderUser();
    },
    changeVisits(positive) {
      if (positive) {
        this.visits++;
        this.visitsChange++;
      } else {
        if (this.visits == 0) return;
        this.visits--;
        this.visitsChange--;
      }
      this.saveProfile();
    },
    async saveProfile() {
      if (this.visitsChange !== 0) await addVisits(this.visitsChange, this.id);
      this.creditsChange = 0;
      this.visitsChange = 0;
      this.renderUser();
    },
    async changeUserDetails(item) {
      const update = prompt(`Enter new ${item}:`, this.user[item]);
      if (!update || update == "") return;
      const response = await updateUser(this.user._id, item, update);
      if (response) {
        this.user[item] = update;
      }
    },
    async changeVerificationStatus(status) {
      const response = await toggleVerification(this.user._id, status);
      if (response) {
        this.user.isVerified = status;
      }
    },
    async changeAdminStatus(status) {
      const response = await toggleAdmin(this.user._id, status);
      if (response) {
        this.user.admin = status;
      }
    }
  },
  mounted() {
    this.renderUser();
  },
}
</script>

<style scoped lang="scss">
@import "/src/assets/styles/variables.scss";

#user {
  position: fixed;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  top: calc(50% - 330px);
  right: calc(50% - 345px);
  display: block;
  z-index: 4;
  border-radius: 5px;
  background: white;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;

  h4 {
    margin: 0;
    color: $colourS;
    font-size: 1.5rem;
  }

  p {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 30px;
    font-size: 18px;

    #toggleShowPackages {
      color: #9d9d9d;
      cursor: pointer;
      transition: 0.1s;
      font-size: 0.8em;
      margin-left: 10px;

      i {
        transition: 0.1s;
      }

      &:hover {
        color: darken(#9d9d9d, 10%);
      }
    }
  }

  #packages {
    width: 100%;
    min-width: 560px;
    border-collapse: collapse;
    table-layout: fixed;

    thead th:nth-child(1) {
      width: 15%;
    }

    thead th:nth-child(2) {
      width: 15%;
    }

    thead th:nth-child(3) {
      width: 18%;
    }

    thead th:nth-child(4) {
      width: 15%;
    }

    thead th:nth-child(5) {
      width: 20%;
    }

    thead th:nth-child(6) {
      width: 7%;
    }

    th {
      border-bottom: solid 1px #ccc;
      padding: 10px 0;
    }

    td {
      padding: 10px 0;
      border-bottom: solid 1px #ccc;
    }

    tr {
      transition: 0.1s;

      &:hover {
        background-color: #f9f9f9;
      }
    }

    i {
      cursor: pointer;
      transition: 0.1s;
      color: #ff0000;

      &.fa-plus {
        color: #00ff00;
      }

      &:hover {
        color: #c32626;

        &.fa-plus {
          color: #24b524;
        }
      }
    }

    input {
      width: 70%;
      padding: 5px;
      border: none;
      border-bottom: solid 1px #ccc;
      background: none;
      color: #333;
    }

    button {
      background: none;
      border: none;
      color: #333;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        color: #000;
      }
    }
  }

  .odd {
    color: rgb(133, 133, 133);
  }

  #credits,
  #visits {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    i {
      font-size: 24px;
      cursor: pointer;
      transition: 0.1s;
      margin: 0 20px;

      &:hover {
        color: #ccc;
      }
    }
  }

  #saveCredits {
    float: right;
    padding: 5px 20px;
    font-size: 1rem;
    border-radius: 50px;
    background: none;
    border: solid 1px #e6e6e6;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: white;
      color: $text;
    }
  }

  #subscription,
  #verified {
    width: 60%;
    display: flex;
    justify-content: space-between;

    button {
      background: none;
      border: none;
      color: #ccc;
      font-size: 16px;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        color: #fff;
      }
    }
  }

  #admin {
    width: 60%;
    display: flex;
    justify-content: space-between;

    button {
      background: repeating-linear-gradient(45deg, rgb(117, 117, 0), rgb(117, 117, 0) 8px, #000 0, #000 16px, rgb(117, 117, 0) 0);
      border: none;
      color: white;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        scale: 1.05;
      }
    }
  }
}

#tint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  #user {
    position: fixed;
    height: 600px;
    width: 90%;
    top: calc(50% - 300px);
    right: 5%;
    display: block;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    text-align: left;
  }

  #tint {
    left: 0;
    width: 100%;
  }
}
</style>