<template>
  <div class="home">
    <h1 v-if="width < 600">Bookings</h1>
    <div id="header">
      <h1 id="left" v-if="width > 600">Bookings</h1>

      <span id="right">
        <div id="weekSelect">
          <a @click='prevWeek' class="fas fa-chevron-left"></a>
          <p>{{ this.date }}</p>
          <a @click='nextWeek' class="fas fa-chevron-right"></a>
        </div>
        <select v-model="site">
          <option v-for="site in sites" style="color: black" :value="site._id.toString()">{{ site.name }}</option>
        </select>
        <b v-if="width > 600" @click="this.exportModal = true" class="fa fa-download"></b>
      </span>
    </div>

    <Bookings v-if="!loading" :site="site" :week="week" />
    <div class="fas fa-spinner" v-if="loading"></div>

    <Export v-if="exportModal" :close="closeExportModal" />
  </div>
</template>

<script>
import Bookings from '@/components/Bookings.vue'
import Export from '@/components/Export.vue'
import { getClientSettings, getSites } from '@/app/admin-routes'

export default {
  name: 'HomeView',
  components: {
    Bookings,
    Export
  },
  data() {
    return {
      sites: [],
      site: '',
      week: 0,
      date: '',
      exportModal: false,
      width: window.innerWidth,
      loading: true,
    }
  },
  methods: {
    nextWeek() {
      // add 86400000 to this.day to trigger reactivity
      this.week += 604800000;
      const nextWeek = this.week + 604800000;
      const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      this.date = new Date(this.week).getUTCDate().toString() + '/' + months[new Date(this.week).getUTCMonth()] + ' - ' + new Date(nextWeek).getUTCDate().toString() + '/' + months[new Date(nextWeek).getUTCMonth()]
    },
    prevWeek() {
      // add 86400000 to this.day to trigger reactivity
      this.week -= 604800000;
      const nextWeek = this.week + 604800000;
      const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      this.date = new Date(this.week).getUTCDate().toString() + '/' + months[new Date(this.week).getUTCMonth()] + ' - ' + new Date(nextWeek).getUTCDate().toString() + '/' + months[new Date(nextWeek).getUTCMonth()]
    },
    closeExportModal() {
      this.exportModal = false;
    }
  },
  async mounted() {
    // Get settings
    const settings = await getClientSettings();
    if (settings.integration != 'bookedsolid') {
      this.$router.push('/messages');
      return;
    }

    // calculate current week
    let newDateFunction = new Date();
    const dateCopy = new Date(newDateFunction.getTime());
    const nextSunday = new Date(
      dateCopy.setDate(dateCopy.getDate() - ((7 + dateCopy.getDay()) % 7))
    );
    const lastSun = nextSunday.toString().substring(0, 15) + " GMT+0000";
    const d = new Date(lastSun);
    const week = Date.parse(d);
    this.week = week;

    // create display date range
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const nextWeek = week + 604800000;
    this.date = new Date(week).getUTCDate().toString() + '/' + months[new Date(week).getUTCMonth()] + ' - ' + new Date(nextWeek).getUTCDate().toString() + '/' + months[new Date(nextWeek).getUTCMonth()]

    // Get client sites
    const sites = await getSites();
    if (!sites.length) this.$router.push('/login?show=site');
    this.sites = sites;
    this.site = sites[0]._id.toString();

    // set key listeners
    document.addEventListener('keydown', event => {
      if (event.keyCode == 37) {
        this.nextWeek()
      } else if (event.keyCode == 39) {
        this.prevWeek()
      }
    })

    this.loading = false;
  }
}
</script>

<style scoped lang="scss">
@import "/src/assets/styles/variables.scss";

.fas.fa-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}

@media only screen and (max-width: 600px) {
  h1 {
    margin-bottom: 0;
  }

  #header {
    margin-top: 10px;

    #left {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      select {
        width: 30vw;
        cursor: pointer;
      }

      a {
        display: block;
        margin-right: 10px;
        font-size: 18px;
      }
    }

    #right {
      width: 140vw;
      height: 38px;

      b {
        padding: 10px 5px;
      }
    }
  }
}
</style>