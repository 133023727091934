<template>
  <div id="export">
    <form @submit.prevent="exportData">
      <p>Export data from {{ start }} to {{ end }}</p>
      <p>Site</p>
      <select name="site" id="site" v-model="site">
        <option v-for="site in sites" :value="site._id.toString()">{{ site.name }}</option>
      </select>
      <br /><br />

      <p>Start Date</p>
      <input type="date" name="start" id="start" v-model="start">
      <br /><br />

      <p>End Date</p>
      <input type="date" name="end" id="end" v-model="end">
      <br /><br />

      <button type="submit">Export</button>
    </form>
  </div>

  <div id="tint" @click="close"></div>
</template>

<script>
import {
  getSites,
  exportBookings
} from '@/app/admin-routes';

export default {
  name: 'Export',
  props: {
    close: Function
  },
  data() {
    return {
      site: '',
      sites: [],
      start: new Date().toDateString(),
      end: new Date().toDateString(),
    }
  },
  methods: {
    async getSites() {
      const sites = await getSites();
      this.sites = sites;
      this.site = sites[0]._id.toString();
    },
    convertToCSV(data) {
      const array = typeof data != 'object' ? JSON.parse(data) : data;
      let str = 'Booking ID,Site ID,User ID,Email,Name,Date,Start,End,Attended,Cancelled,Refunded\r\n';
      console.log(array)

      for (let i = 0; i < array.length; i++) {
        console.log(array[i])
        let line = '';
        line += array[i]['_id'].toString();
        line += ',';
        line += array[i]['site'].toString();
        line += ',';
        line += array[i]['user_id']?.toString() || 'No User ID';
        line += ',';
        line += array[i]['user_email'].toString();
        line += ',';
        line += array[i]['user_name'].toString();
        line += ',';
        line += new Date(array[i]['msec_time']).toISOString().substring(0, 10);
        line += ',';
        line += array[i]['start'].toString();
        line += ',';
        line += array[i]['end'].toString();
        line += ',';
        line += array[i]['attended'] ? 'true' : 'false';
        line += ',';
        line += array[i]['cancelled'] ? 'true' : 'false';
        line += ',';
        line += array[i]['refunded'] ? 'true' : 'false';
        line += '\r\n';
        str += line;
      }
      return str;
    },
    downloadCSV(csv, filename) {
      let csvFile;
      let downloadLink;

      csvFile = new Blob([csv], {
        type: "text/csv"
      });

      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    async exportData() {
      const start = Date.parse(new Date(this.start));
      const end = Date.parse(new Date(this.end)) + 86399999;

      const data = await exportBookings(this.site, start, end);
      if (!data) {
        alert('No bookings found for this site and date range');
        return;
      }
      const csv = this.convertToCSV(data);
      const filename = `${this.site}_Bookings.csv`;
      this.downloadCSV(csv, filename);
      this.close();
    },
  },
  mounted() {
    this.getSites();
  }
}
</script>

<style scoped lang="scss">
@import "/src/assets/styles/variables.scss";

#export {
  position: fixed;
  width: 500px;
  top: calc(50% - 280px);
  right: calc(50% - 295px);
  display: block;
  z-index: 2;
  border-radius: 5px;
  background: white;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;

  p {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 10px;
    margin-top: 30px;
    font-size: 18px;

    &:first-child {
      margin-top: 10px;
    }
  }

  select,
  input {
    background: none;
    padding: 5px;
    border: solid 1px #e6e6e6;
    width: 220px;
    box-sizing: border-box;
    margin: 0;

    option {
      color: black;
    }
  }

  button {
    background: $colourP;
    color: white;
    padding: 8px 50px;
    border-radius: 8px;
    border: none;
    margin-top: 25px;
    font-size: 15px;
    cursor: pointer;
  }
}

#tint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  #export {
    position: fixed;
    height: 500px;
    width: 90%;
    top: calc(50% - 300px);
    right: 5%;
    display: block;
    z-index: 2;
    border-radius: 5px;
    background: rgb(97, 105, 107);
    padding: 20px;
    box-sizing: border-box;
    text-align: left;
  }

  #tint {
    left: 0;
    width: 100%;
  }
}
</style>