import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAdminAuth: true
    },
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "adminLogin" */ '../views/LoginView.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminUsers" */ '../views/UsersView.vue')
  },
  {
    path: '/times',
    name: 'times',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminTimes" */ '../views/TimesView.vue')
  },
  {
    path: '/prices',
    name: 'prices',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminPrices" */ '../views/PricesView.vue')
  },
  {
    path: '/analytics',
    name: 'analytics',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminAnalytics" */ '../views/AnalyticsView.vue')
  },
  {
    path: '/messages',
    name: 'messages',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminMessages" */ '../views/MessagesView.vue')
  },
  {
    path: '/channels',
    name: 'channels',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminChannels" */ '../views/ChannelsView.vue')
  },
  {
    path: '/info',
    name: 'info',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminInfo" */ '../views/InfoView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminSettings" */ '../views/SettingsView.vue')
  },
  {
    path: '/data-harvest',
    name: 'data-harvest',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "data-harvest" */ '../views/DataHarvestView.vue')
  },
  {
    path: '/data-cleaning',
    name: 'data-cleaning',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "data-cleaning" */ '../views/DataCleaningView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.userSession) {
      next({ name: 'login', query: { p: to.name } });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (!localStorage.adminSession) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
