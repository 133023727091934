<template>
  <div id="WEEK">
    <div v-for="(day, index) in this.days" :key="index" class="DAY" :class="index % 2 == 0 ? 'odd' : 'even'">
      <h3>
        {{ day.name }},
        <span>{{ day.date }}</span>
      </h3>

      <Booking v-for="booking in getBookings(index)" :key="booking._id" :booking="booking" :week="this.week" />
    </div>
  </div>
</template>

<script>
import Booking from '@/components/Booking.vue';
import { getBookings } from '@/app/admin-routes';

export default {
  name: 'Bookings',
  components: {
    Booking
  },
  props: {
    site: String,
    week: Number
  },
  data() {
    return {
      bookings: [],
      days: [
        {
          name: "Sun",
          date: new Date(this.week).getUTCDate().toString() + '/' + (Number(new Date(this.week).getUTCMonth()) + 1),
        },
        {
          name: "Mon",
          date: new Date(this.week + 86400000).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000).getUTCMonth()) + 1),
        },
        {
          name: "Tue",
          date: new Date(this.week + 86400000 * 2).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 2).getUTCMonth()) + 1),
        },
        {
          name: "Wed",
          date: new Date(this.week + 86400000 * 3).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 3).getUTCMonth()) + 1),
        },
        {
          name: "Thu",
          date: new Date(this.week + 86400000 * 4).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 4).getUTCMonth()) + 1),
        },
        {
          name: "Fri",
          date: new Date(this.week + 86400000 * 5).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 5).getUTCMonth()) + 1),
        },
        {
          name: "Sat",
          date: new Date(this.week + 86400000 * 6).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 6).getUTCMonth()) + 1),
        }
      ]
    };
  },
  mounted() {
    this.fetchData();
    this.setHeights();
    setTimeout(() => {
      this.setHeights();
    }, 1000);
    for (const day of this.days) {
      if (day.date.split('/')[0].length == 1) {
        day.date = '0' + day.date;
      }
      if (day.date.split('/')[1].length == 1) {
        day.date = day.date.split('/')[0] + '/0' + day.date.split('/')[1];
      }
    }
  },
  watch: {
    async week() {
      await this.fetchData();
      this.setHeights();
      this.setDates();
    },
    async site() {
      await this.fetchData();
      this.setHeights();
      this.setDates();
    }
  },
  methods: {
    async fetchData() {
      if (this.week == 0) return;
      const response = await getBookings(this.site, this.week);
      console.log(this.site, this.week, response)

      // sort bookings into days
      const bookings = [[], [], [], [], [], [], []];
      for (const booking of response) {
        const day = new Date(Number(booking.week) + (booking.dayIndex * 86400000)).getUTCDay();
        bookings[day].push(booking);
      }
      console.log(bookings[0].length, bookings[1].length, bookings[2].length, bookings[3].length, bookings[4].length, bookings[5].length, bookings[6].length)

      // sort bookings by start time
      for (const day of bookings) {
        day.sort((a, b) => {
          return Number(a.start.substring(0,2) + '.' + a.start.substring(3,5)) - Number(b.start.substring(0,2) + '.' + b.start.substring(3,5));
        });
      }

      this.bookings = bookings;
    },
    setHeights() {
      // setTimeout(() => {
      //   const days = document.getElementsByClassName("DAY");
      //   const evens = document.getElementsByClassName("even");
      //   const maxHeight = Math.max(
      //     ...Array.from(days).map((day) => day.scrollHeight)
      //   );
      //   for (const even of evens) {
      //     even.style.height = maxHeight + "px";
      //   }
      // }, 1000);
    },
    setDates() {
      this.days = [
        {
          name: "Sun",
          date: new Date(this.week).getUTCDate().toString() + '/' + (Number(new Date(this.week).getUTCMonth()) + 1),
        },
        {
          name: "Mon",
          date: new Date(this.week + 86400000).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000).getUTCMonth()) + 1),
        },
        {
          name: "Tue",
          date: new Date(this.week + 86400000 * 2).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 2).getUTCMonth()) + 1),
        },
        {
          name: "Wed",
          date: new Date(this.week + 86400000 * 3).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 3).getUTCMonth()) + 1),
        },
        {
          name: "Thu",
          date: new Date(this.week + 86400000 * 4).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 4).getUTCMonth()) + 1),
        },
        {
          name: "Fri",
          date: new Date(this.week + 86400000 * 5).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 5).getUTCMonth()) + 1),
        },
        {
          name: "Sat",
          date: new Date(this.week + 86400000 * 6).getUTCDate().toString() + '/' + (Number(new Date(this.week + 86400000 * 6).getUTCMonth()) + 1),
        }
      ];
      for (const day of this.days) {
        if (day.date.split('/')[0].length == 1) {
          day.date = '0' + day.date;
        }
        if (day.date.split('/')[1].length == 1) {
          day.date = day.date.split('/')[0] + '/0' + day.date.split('/')[1];
        }
      }
    }
  },
  computed: {
    getBookings() {
      return (index) => this.bookings[index];
    },
    getBookingsLength() {
      return (index) => this.bookings[index]?.length || 0;
    }
  }
};
</script>


<style scoped lang="scss">
@import "/src/assets/styles/variables.scss";

#WEEK {
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  border-radius: 5px 5px 0 0;
  margin: 0px 20px;
  margin-bottom: 20px;
  background: white;

  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    border-radius: 0 0 5px 5px;
    z-index: 10;
    background-color: #d6d6d6;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0 0 5px 5px;
    background-color: #b0c4de;
  }

  .even, .odd {
    border-right: solid 1px #d9d9d9;
    
    h3 {
      border-bottom: solid 1px #d9d9d9;
      position: sticky;
      padding-top: 5px;
      top: 0;
      background-color: white;
    }
  }

  .DAY {
    min-height: fill-available;
    height: fit-content;
    min-width: 250px;
    text-align: left;
    padding: 0px 20px;

    h3 {
      width: calc(100% + 40px);
      margin-left: -20px;
      padding-bottom: 10px;
      margin-bottom: 20px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 1.1px;
      font-size: 1em;

      span {
        font-weight: normal;
        margin-left: 20px;
        font-size: inherit;
      }
    }

    .time {
      text-align: center;
      font-size: 1.1em;
    }

    div {
      margin-left: 0px;
    }
  }
}

@media only screen and (max-width: 600px) {
  #WEEK {
    height: calc(100vh - 160px);
  }
}
</style>