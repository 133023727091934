<template>
  <div class="booking">
    <h4 class="time">{{ booking.start }} - {{ booking.end }}</h4>
    <h4>{{ booking.user_name }}</h4>
    <a @click="() => this.showUser = !this.showUser">{{ booking.user_email }}</a><br /><br />
    <i>
      <i>{{ noteText ? noteText : '_' }}</i>
      <i @click="addBookingNote" class="fas fa-pencil"></i>
    </i>
    <section>
      <i @click="markAttendenceFalse" class="fas fa-times" :class="attended === false && 'bgRed'"></i>
      <i @click="markAttendenceTrue" class="fas fa-check" :class="attended === true && 'bgGreen'"></i>
    </section>
  </div>

  <UserModal v-if="showUser" :id="booking.user_id" :email="booking.user_email" :close="closeUserModal" />
</template>

<script>
import UserModal from '@/components/UserModal.vue';
import { addNote, markAttendance } from '@/app/admin-routes';

export default {
  name: 'Booking',
  components: {
    UserModal,
  },
  props: {
    pod: Object,
    booking: Object,
    week: Number,
  },
  data() {
    return {
      time: '',
      noteText: '',
      attended: null,
      showUser: false,
    }
  },
  methods: {
    async addBookingNote() {
      const newNote = await addNote(this.booking._id, this.noteText);
      this.noteText = newNote;
      this.booking.note = newNote;
    },
    markAttendenceTrue() {
      markAttendance(this.booking._id, true, this.booking.appointment_id);
      this.attended = true;
      this.booking.attended = true;
    },
    markAttendenceFalse() {
      markAttendance(this.booking._id, false, this.booking.appointment_id);
      this.attended = false;
      this.booking.attended = false;
    },
    closeUserModal() {
      this.showUser = false;
    }
  },
  mounted() {
    // Set the attended
    this.attended = this.booking.attended;

    // Set the note
    this.noteText = this.booking.note;
  },
}
</script>

<style scoped lang="scss">
@import "/src/assets/styles/variables.scss";

.booking {
  cursor: default;
  height: 230px;
  width: 250px;
  padding: unset;
  border-radius: unset;
  border-bottom: solid 1px $colourT;

  &:last-child {
    border-bottom: none;
  }

  h4 {
    padding: 0 5px;
  }

  .time {
    text-align: center;
    font-size: 1.1em;
  }

  a {
    display: inline-block;
    padding: 5px;
    overflow-x: auto;
    max-width: 100%;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      border-radius: 5px;
      z-index: 10;
      background-color: #d6d6d6;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #b0c4de;
    }
  }

  p {
    padding: 0 5px;
  }

  .fa-pencil {
    padding-left: 5px;
    float: right;
    cursor: pointer;
  }

  &:hover {
    background: none;
  }

  &:hover a:hover {
    background: $colourS;
    border-radius: 3px;
    color: white;
    cursor: pointer;
  }

  section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;

    i {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 95px;
      padding: 5px;
      background: #d9d9d9;
      cursor: pointer;
    }

    .fa-check {
      border-radius: 0px 10px 10px 0px;
    }

    .fa-times {
      border-radius: 10px 0px 0px 10px;
      margin-right: 2px;
    }

    .bgRed {
      background: rgb(180, 0, 0);
      color: white;
    }

    .bgGreen {
      background: rgb(0, 104, 0);
      color: white;
    }
  }
}

.odd {
  .booking {
    border-bottom: solid 1px $colourT;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>